/* General Page Styling */
.gallery-page {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    color: white;
    justify-content: flex-start; /* Align content to the top */
    position: relative;
    padding: 2rem;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Gallery Grid Styles */
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive columns */
    gap: 16px;
    margin-top: 20px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effects */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow to images */
  }
  
  .gallery-image:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Enhanced hover shadow */
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Dark translucent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above other content */
    cursor: pointer;
  }
  
  .expanded-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease; /* Add a fade-in animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }  
  
  /* Loading Message */
  .loading-message {
    text-align: center;
    font-size: 18px;
    color: white;
    margin-top: 50px;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 12px;
    }
  }

  
  