/* Apply consistent background to all pages */
.page {
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
}

.content {
  width: 60%;
  margin: auto;
  padding-top: 4rem;
}

.content p{
  font-weight: 550;
}

.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  padding: 1rem 2rem; /* Added left and right padding to avoid border touching */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.navigation a {
  color: white;
  text-decoration: none;
  font-family: 'Courier', monospace;
  margin: 0 1.5rem;
  transition: color 0.3s ease;
}

/* Add extra space between first and last links */
.navigation a:first-child {
  margin-left: 1.5rem;
}

.navigation a:last-child {
  margin-right: 1.5rem;
}

.navigation a:hover {
  color: #ccc;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .content {
    width: 90%;
    padding-top: 5rem;
  }

  .navigation {
    padding: 1rem 1.5rem; /* Additional padding to the left and right on mobile */
  }
  
  .navigation a {
    margin: 0 0.75rem; /* Reduce spacing between links */
    font-size: 0.9rem;
  }
}
