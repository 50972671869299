.page {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), #000), 
                url('../assets/loveyoubye.jpg') no-repeat top center;
    background-size: cover;
    min-height: 100vh;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .shows-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .show-card {
    background: rgba(0, 0, 0, 0.7);
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
  }
  
  .show-card:hover {
    transform: scale(1.02);
  }
  
  .show-card h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .show-card p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .ticket-button {
    background-color: blue;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .ticket-button:hover {
    background-color: blue;
  }
  