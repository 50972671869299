/* Apply Courier font to all elements */
* {
  font-family: 'Courier', monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Homepage container */
.homepage {
  position: relative;
  width: 100vw;
  min-height: 100vh; /* Ensures full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: white;
  overflow: hidden; /* Prevent horizontal overflow */
  padding: 2rem;
}

/* Video Background */
.background-video {
  position: fixed; /* Stays fixed in place when scrolling */
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8); /* Slightly darker background for text readability */
  z-index: -2; /* Places video behind other content */
}

/* Optional dark overlay for readability */
.homepage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Overlay stays behind the content */
}

/* Content container */
.homepage-content {
  position: relative;
  z-index: 2; /* Ensures content is above the video and overlay */
  max-width: 800px;
  padding: 1rem;
  border-radius: 10px;
}

/* Heading and text styling */
.homepage-content h1 {
  font-size: 3rem;
  margin-bottom: 4.5rem;
}

.homepage-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.homepage-content h3 {
  margin: 1.5rem 0;
}

/* Links */
.homepage-content a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
}

.homepage-content a:hover {
  color: #ccc;
}

/* CTA button */
.cta-button {
  background-color: blue;
  border: none;
  color: white;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: darkblue;
}

/* Spotify Embed */
.spotify {
  margin-top: 3rem;
  width: 100%;
  max-width: 600px;
  height: 352px;
}

/* Social Media Links */
.social-links {
  margin-top: 1rem;
}

.social-links a {
  color: white;
  font-size: 2rem;
  margin: 0 15px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #ccc;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .homepage-content h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .homepage-content p {
    font-size: 1.2rem;
  }
  
  .homepage-content h3 {
    font-size: 1rem;
    margin: 1rem 0;
  }
  
  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}
